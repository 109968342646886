import { graphql } from 'gatsby';
import React from "react";
import PostList from '../../components/Blog/PostList';
import Layout from '../../components/Layout';

interface PageProps {
    data: {
        allMarkdownRemark: {
            edges: any;
        };
    };
    pageContext: {
        tag: string;
    };
}

export const pageQuery = graphql`
  query BlogPostsByTag($tag: String!) {
    allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC },
        filter: { frontmatter: { tags: { in: [ $tag ] } } }
      ) {
        edges {
          node {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMM D, YYYY")
              title
            }
          }
        }
    }
  }
`;


export default ({ data, pageContext: { tag } }: PageProps) => {
    const posts = data.allMarkdownRemark.edges;
    return (
        <Layout>
            <PostList pageTitle={tag} posts={posts} />
        </Layout>
    );
};
